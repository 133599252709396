<template>
	<div v-loading="loading" class="flex flex-col mt-3">
		<div class=" bg-white p-3 mb-4">
			<ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
				<form class="mb-2" @submit.prevent="handleSubmit(updateSettings)">
					<div class="row">
						<div class="col-md-6 mt-3">
							<ValidationProvider name="email" rules="required|email"
												v-slot=" slotData ">
								<div class="form-group">
									<label
										class="flex align-items-center col-form-label font-light whitespace-nowrap ">{{
											$t('email')
										}}<span class="help-tooltip cursor-pointer"
												:title="$t('email')"></span></label>
									<div>
										<input data-cy='band-name' name="email" type="text"
											   v-model="userInformation.email" required
											   class="flex justify-between align-items-center space-x-4 focus:outline-none
											   border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</ValidationProvider>
						</div>
						<div class="col-md-6 mt-3">
							<ValidationProvider name="phone_number" rules="required"
												v-slot=" slotData ">
								<div class="form-group">
									<label
										class="flex align-items-center col-form-label font-light whitespace-nowrap">{{
											$t('phoneNumber')
										}}<span
											class="help-tooltip cursor-pointer"
											:title="$t('phoneNumber')"></span></label>
									<div>
										<input type="text" v-model="userInformation.phone_number"
											   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
									<small class="text-red-500 font-semibold">{{
											slotData.errors[0]
										}}</small>
								</div>
							</ValidationProvider>
						</div>
						<div class="col-md-12 mt-5 mb-4">
                                    <span class="flex justify-center">
                                        <button data-cy='setting-btn' type="submit" :disabled="updating"
												:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
												:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-green-500'"
												class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3">
                                            <span v-if="updating" class="flex align-items-center space-x-3">
                                                <svg class="animate-spin mr-2 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor"
													  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </span>
                                            <span v-else>{{ $t('update') }}</span>
                                        </button>
                                    </span>
						</div>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"
import {ValidationProvider, ValidationObserver, localeChanged} from 'vee-validate'

export default {
	name: "profileSettings",
	components: {
		ValidationObserver, ValidationProvider
	},
	data: function () {
		return {
			loading: false,
			updating: false,
			userInformation: {
				email: "",
				phone_number: ""
			}
		}
	},
	methods: {
		getProfileInformation: function () {
			this.$store.dispatch('customer/getUserInformation', {id: this.GET_USER_ID}).then(res => {
				Object.keys(this.userInformation).map(key => {
					this.userInformation[key] = res.data[key]
				})
			})
		},
		updateSettings: function () {
			this.updating = true
			this.$store.dispatch('customer/updateUserInformation', {
				id: this.GET_USER_ID,
				data: this.userInformation
			}).then(res => {
				this.updating = false
				this.$services.helpers.notification(this.$t('updatedSuccess'), 'success', this)
			}).catch(err => {
				this.updating = false
				window.Bus.$emit('output-error', err)
			})

		}
	},
	computed: {
		...mapGetters({
			GET_USER_ID: 'customer/GET_USER_ID',
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
		})
	},
	mounted() {
		this.getProfileInformation()
	}
}
</script>

<style scoped>

</style>
