<template>
	<div class="app-main__inner">
		<div class="container mb-3">
			<div class="row flex pl-3 pr-3 flex-col md:flex-row justify-between items-center align-items-center mb-3">
				<div data-cy='setting-header'>
					<div class="flex justify-between align-items-center space-x-3 mb-3">
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size"
							  v-if="tab === 4">{{
								$t('myProfile')
							}}</span>
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size"
							  v-if="tab === 1">{{
								$t('address')
							}}</span>
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size"
							  v-if="tab === 2">{{
								$t('settings')
							}}</span>
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size"
							  v-if="tab === 3">{{
								$t('productDetail')
							}}</span>
					</div>
				</div>
				<div
					class="flex flex-col md:flex-row w-11/12 md:w-auto justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xs">
					<span data-cy='tab1' @click="showTab(4)"
						  class="v-company-setting-2 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2 md:rounded-l-md"
						  :class="tab === 4 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('myProfile')
						}}</span>
					<span data-cy='tab1' @click="showTab(1)"
						  class="v-company-setting-2 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2 "
						  :class="tab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('address')
						}}</span>
					<span data-cy='tab1' @click="showTab(2)"
						  class="v-company-setting-2 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2"
						  :class="tab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('settings')
						}}</span>
					<span data-cy='tab1' @click="showTab(3)"
						  class="v-company-setting-2 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2 md:rounded-r-md"
						  :class="tab === 3 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('productDetail')
						}}</span>
				</div>
			</div>
			<div class="row dm-sans-font">
				<div class="col-md-12">
					<profile-settings v-if="tab === 4"/>
					<DeliveryAddress v-if="tab === 1"/>
					<customer-settings v-if="tab === 2"/>
					<product-settings v-if="tab === 3"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DeliveryAddress from "./DeliveryAddress";
import customerSettings from "./component/customerSettings";
import productSettings from "./component/productSettings";
import profileSettings from "./component/profileSettings";

export default {
	name: "Settings",
	data: function () {
		return {
			tab: 1,
			showDeliveryAddress: false
		}
	},
	components: {DeliveryAddress, customerSettings, productSettings, profileSettings},
	methods: {
		showTab: function (tab) {
			this.tab = parseInt(tab)
		},
		getCompanyUser() {
			this.$store.dispatch('customer/getUserCompanyName').then(resp => {
				if (resp.data.data.length > 0) {
					if (resp.data.data[0].self_allowed_create_delivery_addresses === true) {
						this.showDeliveryAddress = true
					}
				}
			})
		}
	},
	mounted() {
		// this.getCompanyUser()
	}
}
</script>

<style scoped>

</style>
