<template>
	<div v-loading="loading" class="flex flex-col mt-3">
		<div class="flex bg-white p-3 mb-4">
			<div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
				<div class="col-span-1">
					<!--					<p class="customer-name">{{ customer.customer_name }}</p>-->
				</div>
				<div class="col-span-1">
					<button v-on:click="addLocation"
							class="w-1/4 float-right text-white px-3 bg-green-500 py-2 rounded-md whitespace-nowrap">
						<span class="text-xs">
                                        {{ $t('add') }}
                                    </span>
					</button>
				</div>
			</div>
		</div>
		<div class="flex mb-4">
			<div class="drawer-category-management table-responsive rounded-lg bg-white border">
				<table style="width: 100%;" id="exampledestroy" class="table table-hover table-bordered">
					<thead>
					<tr>
						<th> {{ $t('locationName') }}</th>
						<th>{{ $t('streetAddress') }}</th>
						<th>{{ $t('zipCode') }}</th>
						<th>{{ $t('country') }}</th>
						<th>{{ $t('city') }}</th>
						<th>{{ $t('phoneNumber') }}</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(item, i) in GET_DELIVERY_ADDRESS.results" :key="i">
						<td>{{ item.location_name }}</td>
						<td>{{ item.street_address }}</td>
						<td>{{ item.zip_code }}</td>
						<td>{{ getCountry(item.country) }}</td>
						<td>{{ item.city }}</td>
						<td>{{ item.phone_number }}</td>
						<td>
							<div class="flex justify-center space-x-2">
                                                <span v-on:click="updateLocation(item)"
													  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer">
                                                    <img src="./../../assets/images/icons/edit.png" alt="">
                                                </span>
								<span v-on:click="deleteLocation(item.id)"
									  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer">
                                                    <img src="./../../assets/images/icons/delete.png" alt="">
                                                </span>
							</div>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" class="form-select" @change="ResultNumber">
                                    <option value="50" selected>50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                </select>
                            </span>
                        </span>
			<div class="pagination-block" v-if="GET_DELIVERY_ADDRESS.results">
				<paginate
					:page-count="GET_DELIVERY_ADDRESS.total_pages"
					:click-handler="changePageNumber"
					prev-text="<"
					next-text=">"
					:container-class="'pagination space-x-2'">
				</paginate>
			</div>
		</div>

	</div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import {mapGetters} from "vuex"
import {ALL_COUNTRY_NAME_CODE} from "../../services/constant";

export default {
	name: "DeliveryAddress",
	data: function () {
		return {
			locationsList: {results: []},
			loading: false,
			limit: 20,
			company_id: ''
		}
	},
	components: {
		paginate: Paginate,
	},
	computed: {
		...mapGetters({
			GET_DELIVERY_ADDRESS: 'customer/GET_DELIVERY_ADDRESS'
		})
	},
	mounted() {
		this.company_id = this.$route.params.company_id
		if (this.company_id && this.company_id !== 'invalid_company') {
			this.getAllDeliveryAddress()
		} else {
			this.$services.helpers.notification(this.$t('no_company_text'), 'error', this)
		}

		window.Bus.$on('reload-customer-delivery-address', e => {
			this.getAllDeliveryAddress()
		})
	},
	methods: {
		updateLocation(data) {
			window.Bus.$emit('open-edit-customer-delivery', data)
		},
		deleteLocation: function (id) {
			this.$store.dispatch('customer/deleteCustomerDeliveryAddress', {id: id}).then(res => {
				let index = this.GET_DELIVERY_ADDRESS.results.findIndex(item => item.id === parseInt(id))
				if (index >= -1) {
					this.GET_DELIVERY_ADDRESS.results.splice(index, 1)
				}
			})
		},
		addLocation: function () {
			window.Bus.$emit('open-add-customer-delivery')
		},
		getAllDeliveryAddress: function () {
			this.loading = true
			this.$store.dispatch('customer/getAllCustomerDeliveryAddress', {data: `?limit=${this.limit}&company_id=${this.company_id}`}).then(_ => {
				this.loading = false
			}).catch(error => {
				this.loading = false
				if (error.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				} else {
					this.$services.helpers.notification(error.response.data.message, 'error', this)
				}
			})
		},
		changePageNumber: function (number) {
			this.$store.dispatch('customer/getAllCustomerDeliveryAddress', {data: `?page=${number}&limit=${this.limit}&company_id=${this.company_id}`}).then(_ => {
				this.loading = false
			}).catch(error => {
				this.loading = false
				if (error.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				} else {
					this.$services.helpers.notification(error.response.data.message, 'error', this)
				}
			})
		},
		ResultNumber: function () {
			this.$store.dispatch('customer/getAllCustomerDeliveryAddress', {data: `?limit=${this.limit}&company_id=${this.company_id}`}).then(_ => {
				this.loading = false
			}).catch(error => {
				this.loading = false
				if (error.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				} else {
					this.$services.helpers.notification(error.response.data.message, 'error', this)
				}
			})
		},
		getCountry: function (symbol) {
			if (ALL_COUNTRY_NAME_CODE.hasOwnProperty(symbol)) {
				return ALL_COUNTRY_NAME_CODE[symbol]
			} else {
				return ''
			}
		}
	}
}
</script>

<style scoped>

</style>
