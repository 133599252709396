<template>
	<div v-loading="loading" class="flex flex-col mt-3">
		<div class="flex bg-white p-3 mb-4">
			<div class="row">
				<div class="col-md-4" v-for="(item, i) in checkFieldForm" :key="i">
					<div class="form-group">
						<label
							class="label-w-input col-form-label font-light whitespace-nowrap  mb-1">
							<input :data-cy='item.label'
								   type="checkbox"
								   v-model="item.value"
								   class="checkbox-height  h-4 w-4">
							<span class="ml-3"> {{ $t(item.label) }}<span
								class="help-tooltip cursor-pointer"
								v-bind:title="$t(item.label)"></span></span>
						</label>

					</div>
				</div>
				<div class="col-md-12 mt-5 mb-4">
                                    <span class="flex justify-center">
                                        <button data-cy='setting-btn' @click="updateSettings" :disabled="updating"
												:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
												:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-green-500'"
												class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3">
                                            <span v-if="updating" class="flex align-items-center space-x-3">
                                                <svg class="animate-spin mr-2 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor"
													  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                {{ $t('updating') }}
                                            </span>
                                            <span v-else>{{ $t('update') }}</span>
                                        </button>
                                    </span>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "productSettings",
	data: function () {
		return {
			loading: false,
			updating: false,
			checkFieldForm: [
				{
					name: 'sales_price',
					label: 'hide_sales_price',
					value: false,
				},
				{
					name: 'discounted_price',
					label: 'hide_discounted_price',
					value: false,
				},
				{
					name: 'recommended_price',
					label: 'hide_recommended_price',
					value: false,
				},
				{
					name: 'delivery_time',
					label: 'hide_delivery_time',
					value: false,
				},
				{
					name: 'stock_quantity',
					value: false,
					label: 'hide_stock_quantity'
				},
			],
			customerInfo: {}
		}
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
			GET_CUSTOMER: 'customer/GET_CUSTOMER_NAME'
		})
	},
	mounted() {
		this.getCompanyUser()
	},
	methods: {
		updateSettings: function () {
			let hidden_fields = []
			let visible_fields = []
			this.updating = true
			this.checkFieldForm.map(item => {
				if (item.value === true) {
					hidden_fields.push(item.name)
				} else {
					visible_fields.push(item.name)
				}
			})
			let payload = {
				id: this.customerInfo.id,
				data: {
					visible_fields: visible_fields,
					hidden_fields: hidden_fields,
				}
			}
			this.$store.dispatch('customer/patchUserSettings', payload).then(resp => {
				this.updating = false
				this.$services.helpers.notification(this.$t('settingUpdated'), 'success', this)
				this.$store.dispatch('customer/getUserAccountSettings')
			}).catch(err => {
				this.updating = false
				window.Bus.$emit('output-error', err)
			})
		},
		getCompanyUser() {
			this.$store.dispatch('customer/getUserAccountSettings').then(resp => {
				this.customerInfo = resp.data.data
				resp.data.data.hidden_fields.map(item => {
					let itemIndex = this.checkFieldForm.findIndex(ind => String(ind.name) === String(item))
					if (itemIndex > -1) {
						this.checkFieldForm[itemIndex].value = true
					}
				})
				resp.data.data.visible_fields.map(item => {
					let itemIndex = this.checkFieldForm.findIndex(ind => String(ind.name) === String(item))
					if (itemIndex > -1) {
						this.checkFieldForm[itemIndex].value = false
					}
				})
			})
		},
	}
}
</script>

<style scoped>

</style>
